//
//
//

import { defineComponent } from 'vue-demi';

import { runHook } from '../hooks';
import { isDevMode } from '../utils/debug';
import { fetchPageContent, resolvePageComponent, setNuxtContext } from '../fetch';
import { RouterErrorType } from '../fetch/error';
import { PROVIDER_PAGE_CONTENT, PROVIDER_PAGE_CONTENT_ID, PROVIDER_PAGE_CONTENT_TYPE, PROVIDER_PAGE_META_PROPERTIES } from '../utils/providers';

export default defineComponent({
  name: 'PageRouter',
  provide () {
    return {
      [PROVIDER_PAGE_CONTENT_TYPE]: this.pageContentType,
      [PROVIDER_PAGE_CONTENT_ID]: this.pageContentId,
      [PROVIDER_PAGE_CONTENT]: this.pageContent,
      [PROVIDER_PAGE_META_PROPERTIES]: this.pageMetaProperties,
    };
  },
  validate (context) {
    context.routerStore = {};
    const { route, from, routerStore, redirect } = context;

    const oldPath = from?.path.replace(/\?.*/, '');
    const path = route.path.replace(/\?.*/, '');

    // Continue without fetch.
    // Does not work on full CSR where context.from and
    // context.route will be the same for some reason
    if (context.$config.ssr && oldPath === path)
      return true;

    let url = path;
    try { url = decodeURI(path) } catch (e) {}

    runHook('router:page-load', url);
    setNuxtContext(context);

    return fetchPageContent(url)
      .then((page) => {
        routerStore.data = page;
        return !!page.pageContentType;
      })
      .catch((error) => {
        if (error.type === RouterErrorType.Redirected)
          return false;

        if (error.type === RouterErrorType.PageNotFound)
          return false;

        if (error.type === RouterErrorType.ContentTypeNotImplemented)
          return isDevMode ? Promise.reject(new Error('Sidan är inte implementerad')) : redirect('/');

        if (isDevMode) {
          console.error(error);
          console.error(error.nativeError);
        }

        return Promise.reject(new Error('Ett fel har inträffat'));
      })
      .catch((error) => {
        runHook('router:error', error);
        return Promise.reject(error);
      });
  },
  async asyncData ({ routerStore }) {
    // Preload async component
    await resolvePageComponent(routerStore.data.pageContentType)();

    // Use page data fetched in validate()
    return routerStore.data;
  },
  /** @type {ReturnType<typeof fetchPageContent>} */
  data () {
    return {
      pageLayout: null,
      pageContentType: null,
      pageContentId: null,
      pageContent: null,
      pageMetaProperties: null,
      pageMetaJsonld: [],
      workspaceContent: null,
    };
  },
  head () {
    const jsonld = [...this.pageMetaJsonld];
    /** @type {import('../fetch/page-content').PageMetaProperties} */
    const meta = this.pageMetaProperties;

    if (!this.pageMetaProperties)
      return {};

    let imageMeta = [];
    if (meta.image)
      imageMeta = [
        { hid: 'og:image', property: 'og:image', content: meta.image.variant.url },
        { hid: 'og:image:width', property: 'og:image:width', content: meta.image.variant.width },
        { hid: 'og:image:height', property: 'og:image:height', content: meta.image.variant.height },
      ];

    if (this.metaSearchBox)
      jsonld.push(this.metaSearchBox);

    return {
      title: meta.title,
      meta: [
        { hid: 'og:title', property: 'og:title', content: meta.title },
        { hid: 'og:description', property: 'og:description', content: meta.description },
        { hid: 'description', name: 'description', content: meta.description },
        { hid: 'og:type', property: 'og:type', content: 'Article' },
        { hid: 'og:article:published_time', property: 'og:article:published_time', content: meta.datePublished },
        { hid: 'og:article:modified_time', property: 'og:article:modified_time', content: meta.datePublished },
        ...imageMeta,
      ],
      script: jsonld.map(json => ({
        type: 'application/ld+json',
        json,
      })),
    };
  },
  computed: {
    metaSearchBox () {
      const { searchUrl } = this.$cms.config;
      if (!searchUrl)
        return null;

      return {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: this.workspaceContent.url,
        potentialAction: {
          '@type': 'SearchAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: this.workspaceContent.url + searchUrl,
          },
          'query-input': 'required name=search_term_string',
        },
      };
    },
    pageComponent () {
      return resolvePageComponent(this.pageContentType);
    },
  },
  created () {
    this.$nuxt.setLayout(this.pageLayout);
    runHook('router:page-load:done');
  },
});
